<template>
  <v-container fluid :class="MobileClassML0">
    <v-row v-for="toolrow in GroupedTools" :key="toolrow.Name" :class="MobileClass">
      <v-col
        :cols="widthForColumn(toolrow)"
        v-for="tool in toolrow"
        :key="tool._ctr"
        :class="MobileClassX"
      >
        <ToolCardView
          :toolname="tool.Name"
          :AllToolList="toolrow"
          :RawToolList="RawTools"
          :CtrToolToShow="tool._ctr"
          :DefaultViewmode="defaultView(tool)"
          :ForceViewSequence="getForceViewSequence(tool)"
          :auth="authObject"
          :initialState="getInitialState(tool)"
          @saveModification="saveModification"
          @toggleExpand="toggleExpand"
          @stateChanged="stateChanged(tool, $event)"
          @modalToolView="modalViewRequest(tool, $event)"
          @modalPhotoView="modalPhotoRequest(tool, $event)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ToolCardView from "@/components/dataview/ToolCardView";
import ProcessToolData from "@/support/processToolData.js";
//import ViewStructures from "./ViewStructure.js";

export default {
  components: {
    ToolCardView,
  },

  data: () => ({
    //editable: true,
    listToExpand: {},
  }),
  props: {
    hierarchyStyle: {
      // full - all groups. none = zero groups, one = just the group containing the tools
      default() {
        return ["full"];
      },
      type: Array,
    },
    oneGroupPerRow: { default: false, type: Boolean }, // if set, then each top-level group will go into a new row
    ToolViewList: {
      default() {
        return {};
      },
      type: Object,
    },
    authObject: {
      default() {
        return {};
      },
      type: Object,
    },
    InjectedTools: {
      default() {
        return {};
      },
      type: Object,
    },
    initialExpansionList: {
      default() {
        return {};
      },
      type: Object,
    },
    initialState: {
      default() {
        return {};
      },
      type: Object,
    },
    DefaultViewmode: {
      default() {
        return "Pictures";
      },
      type: String,
    },
    ForceViewSequence: {
      default() {
        return "";
      },
      type: String,
    },
    resetExpansionList: { default: 0, type: Number },
    searchText: { default: "", type: String },
  },

  watch: {
    initialExpansionList: function (val) {
      //      console.log("New Expand List");
      //    console.log(JSON.stringify(val));
      this.listToExpand = JSON.parse(JSON.stringify(val));
    },
    resetExpansionList: function () {
      //  console.log("Reset Expand List");
      //console.log(JSON.stringify(this.listToExpand));
      this.listToExpand = JSON.parse(JSON.stringify(this.initialExpansionList));
    },
  },

  computed: {
    ViewStructures() {
      return this.$store.getters.getViewStructure;
    },
    MobileClass() {
      if (this.$vuetify.breakpoint.smAndDown) return "ma-0 pa-0";
      return undefined;
    },
    MobileClassML0() {
      if (this.$vuetify.breakpoint.smAndDown) return "ma-0 pa-0";
      return "ml-0";
    },
    MobileClassX() {
      if (this.$vuetify.breakpoint.smAndDown) return "mx-0 px-0";
      return "pl-0";
    },
    ToolStatus() {
      //console.log("~~~ get status");
      return this.$store.getters["tools/getToolStatus"];
    },
    AllTools() {
      //console.log("~~~ get all tools");
      var AllToolsToUse = this.$store.getters["tools/getTools"];
      return AllToolsToUse;
    },
    RawTools() {
      //console.log("get raw tools");
      var AllToolsToUse = this.$store.getters["tools/getRawTools"];
      return AllToolsToUse;
    },
    ToolsToDisplay() {
      if (this.ToolViewList === undefined) return {};
      var AllToolsToUse = this.AllTools;
      if (Object.keys(this.InjectedTools).length !== 0) {
        AllToolsToUse = JSON.parse(JSON.stringify(this.InjectedTools)); // needed to avoid infinite loop...
      }
      return ProcessToolData.PopulateToolViewList(this.ToolViewList, AllToolsToUse);
    },
    CollapsedTools() {
      /***
       * Displaying the tools
       *
       * Filter them by search term first
       * Then process the derivations
       * derived quantities are not filtered, and focus on just the filter results
       * that's important because lists of features (e.g. gases, materials, etc) should
       * focus on only the tools that are part of the results.
       *
       * Derived properties are meant for groups, but they can also be applied to itself
       * if processing is to be done on inputs (e.g. feature extraction from other fields, etc)
       * in which case a custom function (instead of OR AND ...) would be needed
       *
       * the final step is to faltten the hierarchy, removing grouping if needed and
       * showing tools for expanded groups (and hiding them for collapsed ones)
       *
       */
      try {
        console.log("TV.a - Collapsed Tools");
        var toollist = this.ToolsToDisplay;
        var searchTerms = [];
        if (this.searchText !== "") {
          searchTerms = this.searchText.toLowerCase().split(" ");
        }
        ProcessToolData.FilterToolViewList(toollist, searchTerms);
        ProcessToolData.processDerivatives(toollist);
        ProcessToolData.processBuild(toollist, this.AllTools);
        ProcessToolData.setExpandedFlags(toollist, this.listToExpand); // notifyUpdate added as a dummy variable to react to changes in toggleExpand
        console.log(JSON.stringify(toollist));
        console.log(this.hierarchyStyle);
        toollist = ProcessToolData.FlattenToolViewList(toollist, {
          oneGroupPerRow: this.oneGroupPerRow,
          hierarchy: this.hierarchyStyle,
        });
        console.log("TV - Done");
        console.log(JSON.stringify(toollist));
        return JSON.parse(JSON.stringify(toollist));
      } catch (er) {
        console.log("Error in collapsing tools " + er.toString());
        console.log(er.stack);
        return [];
      }
    },
    GroupedTools() {
      return ProcessToolData.SplitIntoSingleRows(this.CollapsedTools);
    },
  },
  methods: {
    async saveModification(evt) {
      console.log("~~~~~ TV - saveModification");
      console.log(JSON.stringify(evt));

      //this.$emit("saveModification", evt);
    },
    getInitialState(tool) {
      console.log("Get initial state for " + tool.Name);
      if (this.initialState[tool.Name] === undefined) {
        console.log("Undefined initial state");
        return {};
      }
      console.log("State = " + JSON.stringify(this.initialState[tool.Name]));
      return this.initialState[tool.Name]._state;
    },
    isFullWidth(entry) {
      if (entry.FullWidth === undefined) return false;
      // fullwidth is either a bool, or an array of target types
      if (entry.FullWidth === true) return true;
      if (Array.isArray(entry.FullWidth)) {
        if (entry.FullWidth.includes(entry.Type)) return true;
      }
      return false;
    },
    getForceViewSequence(tool) {
      if (this.isFullWidth(tool)) {
        if (tool.FullWidthViewSequence !== undefined) {
          return tool.FullWidthViewSequence;
        }
        if (tool.FullWidthView !== undefined) {
          return tool.FullWidthView;
        }
        return "FullWidthHeader";
      }
      if (tool.RegularViewSequence !== undefined) {
        return tool.RegularViewSequence;
      }
      return this.ForceViewSequence;
    },
    widthForColumn(toolrow) {
      if (toolrow.length == 1) {
        if (this.isFullWidth(toolrow[0])) {
          return 12;
        }
      }
      return "auto";
    },
    toggleExpand(ctrToFlip) {
      console.log("toggle " + JSON.stringify(ctrToFlip));
      if (this.listToExpand[ctrToFlip] === undefined) {
        this.listToExpand[ctrToFlip] = true;
      } else {
        this.listToExpand[ctrToFlip] = !this.listToExpand[ctrToFlip];
      }
      // to force an update, replace the object (since vue is not watching when things are modified inside the object)
      console.log(JSON.stringify(this.listToExpand));
      this.listToExpand = { ...this.listToExpand };
      this.$emit("expansionModified", this.listToExpand);
    },

    defaultView(tool) {
      if (this.isFullWidth(tool)) {
        var fws = this.getForceViewSequence(tool);
        // return the 1st entry of the view sequence
        var result = this.ViewStructures.toolViewSequence[fws];
        if (result !== undefined) {
          return result[0].name;
        }
      }
      if (tool.DefaultView !== undefined) return tool.DefaultView;
      return this.DefaultViewmode;
    },
    stateChanged(tool, evt) {
      tool._state = { ...tool._state, ...evt._state };
      // modify internal state and emit event
      this.$emit("stateChanged", { ...evt, Name: tool.Name });
    },
    async modalPhotoRequest(tool, evt) {
      console.log(evt._state.ToolName);
      var tableListToShow = this.CollapsedTools[0].map((el) => el.Name);
      var showIndex = tableListToShow.findIndex((el) => el === evt._state.ToolName);
      await this.$root.$onephotodialog(tableListToShow, showIndex, {
        authObject: this.authObject,
      });
    },
    async modalViewRequest(tool, evt) {
      //tool._state = { ...tool._state, ...evt._state };
      // modify internal state and emit event
      //this.$emit("stateChanged", { ...evt, Name: tool.Name });
      console.log(evt._state.CardView);
      console.log("FIND TOOL " + evt._state.ToolName);
      var tableListToShow = this.CollapsedTools[0].map((el) => el.Name);
      console.log(JSON.stringify(tableListToShow));
      var showIndex = tableListToShow.findIndex((el) => el === evt._state.ToolName);
      await this.$root.$onetooldialog(tableListToShow, showIndex, {
        authObject: this.authObject,
      });
    },
  },
};
</script>
