<template>
  <v-container fluid class="ml-0">
    <v-row align="center">
      <v-select
        :items="HeaderChoices"
        label="View Styles"
        v-model="headerToShow"
        class="mx-2"
      >
      </v-select>
      <!--
      <v-spacer />
      <v-btn
        v-if="canEdit && editMode"
        class="ma-2"
        color="secondary"
        @click="editMode = !editMode"
      >
        Edit Table: On
      </v-btn>
      <v-btn
        v-if="canEdit && !editMode"
        class="ma-2"
        color="primary"
        @click="editMode = !editMode"
      >
        Edit Table: Off
      </v-btn>
      --><!-- @click:row="showToolDialog" -->
    </v-row>

    <v-row class="fill-height">
      <v-data-table
        :headers="HeaderContent"
        :items="TableItems"
        item-key="_ctr"
        dense
        fixed-header
        class="elevation-1"
        :items-per-page="-1"
        ref="toolTableRef"
        mobile-breakpoint="0"
        @click:row="showToolDialog"
      >
        <template #item.Email="{ item }">
          <a :href="'mailto:' + item.Email + '@mit.edu'" @click="dontShow()">
            {{ item.Email }}
          </a>
        </template>
        <template #item.Staff="{ item }">
          <a href="#" @click="showStaff(item)">
            {{ item.Staff }}
          </a>
        </template>
      </v-data-table>
    </v-row>
    <v-row>
      <v-col>
        <v-btn small @click="downloadTable">Download as excel</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProcessToolData from "@/support/processToolData.js";
import DataPrep from "@/support/dataPrep.js";
//import ViewStructures from "./ViewStructure.js";
import ExportExcel from "@/support/export2excel.js";
var FileSaver = require("file-saver");

var moment = require("moment");
export default {
  data: () => ({
    //editable: true,
    listToExpand: {},
    headerToShow: null,
    editMode: false,
    sortBy: [],
    LinkDialogActive: false,
    SupressDialog: false,
  }),
  props: {
    hierarchyStyle: { default: "all", type: String }, // full - all groups. none = zero groups, one = just the group containing the tools
    oneGroupPerRow: { default: false, type: Boolean }, // if set, then each top-level group will go into a new row

    ToolViewList: {
      default() {
        return {};
      },
      type: Object,
    },
    authObject: {
      default() {
        return {};
      },
      type: Object,
    },
    InjectedTools: {
      default() {
        return {};
      },
      type: Object,
    },
    initialExpansionList: {
      default() {
        return {};
      },
      type: Object,
    },
    initialState: {
      default() {
        return {};
      },
      type: Object,
    },
    resetExpansionList: { default: 0, type: Number },
    searchText: { default: "", type: String },
    sublistName: { default: "", type: String },
  },

  beforeMount() {
    console.log("~~~ Tool Table start mounting");
  },

  mounted() {
    console.log("~~~ Tool Table mounted");
    // is there a header choice in the parameters of the URL? If so, use that. Otherwise, default to 1st entry in table.
    var idx = 0;
    if (this.$route.params.option !== undefined) {
      var options = this.$route.params.option.split("-");
      var k;
      for (k = 0; k < this.HeaderShortcuts.length; k++) {
        if (options.includes(this.HeaderShortcuts[k])) {
          idx = k;
          break;
        }
      }
      // check if sort order included
      for (k = 0; k < options.length; k++) {
        if (options[k].startsWith("sort.")) {
          var sortInstructions = options[k].split(".");
          var sortByList = [];
          for (var m = 1; m < sortInstructions.length; m++) {
            var entry = sortInstructions[m];
            if (entry.startsWith("a")) {
              sortByList.push({ key: entry.substring(1), order: "asc" });
            }
            if (entry.startsWith("d")) {
              sortByList.push({ key: entry.substring(1), order: "desc" });
            }
          }
          console.log("SORT BY " + JSON.stringify(sortByList));
          this.sortBy = sortByList;
        }
      }
    }
    this.headerToShow = this.HeaderChoices[idx];
  },
  watch: {
    // whenever question changes, this function will run
    HeaderChoices(newList) {
      // if header to show is not part of the list, default to first item
      if (!newList.includes(this.headerToShow)) {
        console.log("replacing unknown header to show");
        this.headerToShow = newList[0];
      }
    },
  },
  computed: {
    ViewStructures() {
      return this.$store.getters.getViewStructure;
    },
    canEdit() {
      return this.authObject.editEnable;
    },
    TableItems() {
      var toolsToShow = this.CollapsedTools;
      if (toolsToShow === undefined) {
        return [];
      }
      const showChildren =
        this.getHeaderFromLabel(this.headerToShow).hideChildren !== true;
      var filterBy = this.getHeaderFromLabel(this.headerToShow).filterBy;
      if (filterBy === undefined) filterBy = { mustHave: { Type: "Tool" } };
      return this.flattenToTableView(toolsToShow[0], showChildren, filterBy);
    },
    HeaderList() {
      // get the header list, and inject color classes
      var headerdata = JSON.parse(JSON.stringify(this.ViewStructures.tableViews));
      for (var [, entry] of Object.entries(headerdata)) {
        for (var column of entry.Columns) {
          column.class = this.HeaderStyle;
          column.key = column.value.toLowerCase();
        }
      }
      return headerdata;
    },
    HeaderChoices() {
      var results = [];
      for (const [, entry] of Object.entries(this.HeaderList)) {
        var includeThis = true;
        if (this.sublistName !== "") {
          if (entry.mustHave !== undefined) {
            includeThis = includeThis && entry.mustHave.includes(this.sublistName);
          }
          if (entry.cantHave !== undefined) {
            includeThis = includeThis && !entry.cantHave.includes(this.sublistName);
          }
        }
        if (includeThis) results.push(entry.Label);
      }
      return results;
    },
    HeaderShortcuts() {
      var results = [];
      for (const [, entry] of Object.entries(this.HeaderList)) {
        results.push(entry.LinkName);
      }
      return results;
    },

    TableHeaders() {
      //console.log("Table Headers");
      console.log("Table Headers = " + JSON.stringify(this.headerToShow));
      for (const [, entry] of Object.entries(this.HeaderList)) {
        if (entry.Label === this.headerToShow) return entry;
      }
      console.log("Headers: " + JSON.stringify(this.HeaderList));
      return this.HeaderList.Default;
    },
    HeaderContent() {
      var result = this.TableHeaders.Columns;
      result.push({
        text: "Name",
        align: " d-none", // to hide column
        value: "_KEY_",
        source: "Name",
      });
      return result;
    },
    ToolStatus() {
      console.log("get status");
      return this.$store.getters["tools/getToolStatus"];
    },
    AllTools() {
      console.log("get all tools");
      return this.$store.getters["tools/getTools"];
    },
    RawTools() {
      console.log("get raw tools");
      return this.$store.getters["tools/getRawTools"];
    },
    ToolsToDisplay() {
      if (this.ToolViewList === undefined) {
        console.log("No data to show yet");
        return undefined;
      }
      var AllToolsToUse = this.AllTools;
      for (const [coralname, entry] of Object.entries(this.ToolStatus)) {
        if (AllToolsToUse[coralname] !== undefined) {
          var s = "";
          if (entry.shutdowns > 0) {
            AllToolsToUse[coralname].shutdowns = entry.shutdowns;
            AllToolsToUse[coralname].StatusText = "Shutdown";
            var shutdownDate = moment().valueOf();
            if (entry.shutdownDetails !== undefined) {
              for (var k = 0; k < entry.shutdownDetails.length; k++) {
                if (k > 0) s = s + " and ";
                s = s + entry.shutdownDetails[k].definition;
                shutdownDate = Math.min(
                  shutdownDate,
                  moment(entry.shutdownDetails[k].reported).valueOf()
                );
              }
            }
            AllToolsToUse[coralname].StatusDate = shutdownDate;
          } else if (entry.problems > 0) {
            AllToolsToUse[coralname].problems = entry.problems;
            AllToolsToUse[coralname].StatusText = "Problem";
            if (entry.problemDetails.length > 0) {
              var problemDate = moment().valueOf();
              console.log(JSON.stringify(entry));
              if (entry.problemDetails !== undefined) {
                problemDate = moment(
                  entry.problemDetails[entry.problemDetails.length - 1].reported
                ).valueOf();
                s = entry.problemDetails[entry.problemDetails.length - 1].definition;
              }
              AllToolsToUse[coralname].StatusDate = problemDate;
            } else {
              AllToolsToUse[coralname].StatusDate = "";
            }
          } else {
            AllToolsToUse[coralname].StatusText = "";
            AllToolsToUse[coralname].StatusDate = "";
          }
          AllToolsToUse[coralname].StatusReason = s;
        }
      }

      if (Object.keys(this.InjectedTools).length !== 0) {
        AllToolsToUse = JSON.parse(JSON.stringify(this.InjectedTools)); // needed to avoid infinite loop...
      }
      // if the current view style overrides what is being displayed, inject it here:
      var toolViewListToUse = this.ToolViewList;
      var tableHeaderToUse = this.TableHeaders;
      if (tableHeaderToUse !== undefined && tableHeaderToUse.Contents !== undefined) {
        toolViewListToUse = ProcessToolData.GenerateViewInstructions(
          tableHeaderToUse.Contents,
          AllToolsToUse
        );
        console.log("TVL " + JSON.stringify(toolViewListToUse));
      }
      const result = ProcessToolData.PopulateToolViewList(
        toolViewListToUse,
        AllToolsToUse
      );
      console.log("TTD " + JSON.stringify(result));
      return result;
    },
    CollapsedTools() {
      /***
       * Displaying the tools
       *
       * Filter them by search term first
       * Then process the derivations
       * derived quantities are not filtered, and focus on just the filter results
       * that's important because lists of features (e.g. gases, materials, etc) should
       * focus on only the tools that are part of the results.
       *
       * Derived properties are meant for groups, but they can also be applied to itself
       * if processing is to be done on inputs (e.g. feature extraction from other fields, etc)
       * in which case a custom function (instead of OR AND ...) would be needed
       *
       * the final step is to faltten the hierarchy, removing grouping if needed and
       * showing tools for expanded groups (and hiding them for collapsed ones)
       *
       */
      try {
        console.log("TT - Collapsed Tools");

        var toollist = this.ToolsToDisplay;
        //console.log("CT1 " + JSON.stringify(toollist));
        var searchTerms = [];
        if (this.searchText !== "") {
          console.log("Search " + this.searchText);
          searchTerms = this.searchText.toLowerCase().split(" ");
        }
        ProcessToolData.FilterToolViewList(toollist, searchTerms);
        ProcessToolData.processDerivatives(toollist);
        ProcessToolData.processBuild(toollist, this.AllTools);
        ProcessToolData.setExpandedFlags(toollist, this.listToExpand); // notifyUpdate added as a dummy variable to react to changes in toggleExpand
        console.log("CT TL " + JSON.stringify(toollist));
        console.log(this.hierarchyStyle);
        const includeBuild =
          this.getHeaderFromLabel(this.headerToShow).includeBuild == true;
        toollist = ProcessToolData.FlattenToolViewList(toollist, {
          oneGroupPerRow: this.oneGroupPerRow,
          hierarchy: this.hierarchyStyle,
          includeBuild: includeBuild,
        });
        //console.log("completed");
        console.log("CT1 " + JSON.stringify(toollist));
        console.log("TT - Collapsed Tools Done");

        return JSON.parse(JSON.stringify(toollist));
      } catch (er) {
        console.log("Error in collapsing tools " + er.toString());
        console.log(er.stack);
        return [];
      }
    },

    Styling() {
      return this.$store.getters["getStyling"];
    },
    HeaderStyle() {
      return this.StylingByName("tableview.header");
    },
  },
  methods: {
    async downloadTable() {
      console.log("Download Data");
      //var blob = new Blob(["Hello, world!"], { type: "text/plain;charset=utf-8" });
      var buff = await ExportExcel.tableConvert(this.TableItems, this.HeaderContent);
      var blob = new Blob([buff], { type: "text/plain;charset=utf-8" });
      FileSaver.saveAs(blob, "nano_table.xlsx");
    },

    StylingByName(source, defaultvalue = undefined) {
      var entry = this.Styling;
      for (const word of source.split(".")) {
        if (entry[word] === undefined) return defaultvalue;
        entry = entry[word];
      }
      return entry;
    },

    testBlur() {
      console.log("blur");
    },

    getHeaderFromLabel(label, defaultValue = {}) {
      for (const [, entry] of Object.entries(this.HeaderList)) {
        if (entry.Label == label) {
          return entry;
        }
      }
      return defaultValue;
    },

    async dontShow() {
      if (this.editMode) return;
      this.SupressDialog = true;
      return true;
    },

    async showStaff(entry) {
      if (this.editMode) return;
      var staffname = entry.Staff;
      console.log("Show Staff Dialog");
      // find entry where Special = Staff & Label = staffname
      for (const [coralname, entry] of Object.entries(this.AllTools)) {
        if (entry.Label == staffname) {
          this.LinkDialogActive = true;
          await this.$root.$onetooldialog([coralname], 0, {
            authObject: this.authObject,
            UseView: "StaffViewWithDetails",
          });
          this.LinkDialogActive = false;
          return true;
        }
      }
      return false;
    },

    async showToolDialog(evt, tableData) {
      if (this.editMode) return;
      if (tableData == null) return;
      if (this.LinkDialogActive) return;
      if (this.SupressDialog) {
        this.SupressDialog = false;
        return;
      }
      console.log("Show Tool Dialog");
      //console.log("Show tool " + JSON.stringify(evt));
      //console.log("Data " + JSON.stringify(tableData));
      if (evt["_KEY_"] !== undefined) {
        var tableListToShow = this.TableItems.map((el) => el["_KEY_"]);
        var showIndex = tableListToShow.findIndex((el) => el === evt["_KEY_"]);
        await this.$root.$onetooldialog(tableListToShow, showIndex, {
          authObject: this.authObject,
        });
      }
    },

    saveModification(evt) {
      console.log(JSON.stringify(evt));
      console.log("save");
    },
    getInitialState(tool) {
      //console.log(tool.Name);
      if (this.initialState[tool.Name] === undefined) return {};
      console.log("State = " + JSON.stringify(this.initialState[tool.Name]));
      return this.initialState[tool.Name]._state;
    },

    flattenToTableView(toollist, includeChildren, filterBy) {
      // map the tools into the header
      var results = [];
      const headers = this.HeaderContent;
      //console.log(JSON.stringify(filterBy));
      for (const [, entry] of Object.entries(toollist)) {
        // apply the filters
        var showThis = true;
        if (entry._hidden !== true) {
          if (filterBy.mustHave !== undefined) {
            for (const [name, value] of Object.entries(filterBy.mustHave)) {
              showThis = showThis & (entry[name] === value);
            }
          }
          if (filterBy.cantHave !== undefined) {
            for (const [name, value] of Object.entries(filterBy.cantHave)) {
              showThis = showThis & (entry[name] !== value);
            }
          }
        }
        //  (entry.Type === "Tool" || entry.Special === "Staff") &&

        if (showThis) {
          var tableentry = {};
          for (const headerEntry of headers) {
            tableentry[headerEntry.value] = DataPrep.getTextDataForTool(
              headerEntry.source,
              entry
            );
          }
          results.push(tableentry);
        }

        if (entry._children !== undefined && includeChildren)
          results.push(
            ...this.flattenToTableView(entry._children, includeChildren, filterBy)
          );
      }
      //console.log("RR " + JSON.stringify(results));
      return results;
    },
    toggleExpand(ctrToFlip) {
      console.log("toggle " + JSON.stringify(ctrToFlip));
      if (this.listToExpand[ctrToFlip] === undefined) {
        this.listToExpand[ctrToFlip] = true;
      } else {
        this.listToExpand[ctrToFlip] = !this.listToExpand[ctrToFlip];
      }
      // to force an update, replace the object (since vue is not watching when things are modified inside the object)
      console.log(JSON.stringify(this.listToExpand));
      this.listToExpand = { ...this.listToExpand };
      this.$emit("expansionModified", this.listToExpand);
    },
    defaultView(tool) {
      console.log("TT - defaultView " + tool.DefaultView);
      if (tool.DefaultView !== undefined) return tool.DefaultView;
      return "";
    },
    stateChanged(tool, evt) {
      tool._state = { ...tool._state, ...evt._state };
      // modify internal state and emit event
      this.$emit("stateChanged", { ...evt, Name: tool.Name });
    },
  },
};
</script>
