const moment = require("moment");
const xl = require('excel4node');

var ExportExcel = {}

/**
 * Export the vue datatable into an excel spreadsheet and return it as a buffer 
 * @param {array} tabledata - the vue datatable contents
 * @param {array} tableheaders - the vue datatable headers
 * @returns {Buffer} - the completed excel workbook file
 * 
 * Helper used to export dataview table into an excel file. Copies all of the data
 * except the hidden _KEY_ element. Adds a timestamp on the header row.
 */
ExportExcel.tableConvert = async function (tabledata, tableheaders) {
    var wb = new xl.Workbook({ dateFormat: 'm/d/yy hh:mm:ss' })
    var ws = wb.addWorksheet('Nano Tools');
    var styleHeader = wb.createStyle({
        font: {
            bold: true,
            size: 12,
        },
        border: { bottom: { style: 'thick', color: "#000000" } },
    });
    var x = 1
    for (const entry of tableheaders) {
        if (entry.value == "_KEY_") continue
        ws.cell(1, x)
            .string(entry.text)
            .style(styleHeader);
        var y = 2
        for (const data of tabledata) {
            var value = data[entry.value]
            if (value !== undefined) {
                if (typeof value === 'string') {
                    ws.cell(y, x)
                        .string(data[entry.value])
                } else {
                    ws.cell(y, x)
                        .number(data[entry.value])
                }
            }
            y = y + 1
        }
        x = x + 1
    }
    var s = "Saved on " + moment().format("ddd MMM DD @ hh:mma")
    ws.cell(1, x)
        .string(s)

    return await wb.writeToBuffer()
}

module.exports = ExportExcel