<template>
  <v-container fluid v-if="hasFinishedLoading" :class="MobileClass" dense>
    <ToolTable
      v-if="showAsTable"
      :authObject="authObject"
      :ToolViewList="ToolViewList"
      :searchText="searchText"
      :InjectedTools="injectedAllTools"
      :resetExpansionList="resetPageCtr"
      :initialExpansionList="expansionList"
      :initialState="overrideStateBuffered"
      :sublistName="sublistToUse"
      @expansionModified="saveExpansion"
      @stateChanged="stateChanged"
    />
    <ToolView
      v-else
      :authObject="authObject"
      :ToolViewList="ToolViewList"
      :searchText="searchText"
      :InjectedTools="injectedAllTools"
      :resetExpansionList="resetPageCtr"
      :initialExpansionList="expansionList"
      :initialState="overrideStateBuffered"
      :hierarchyStyle="useHierarchyStyle"
      :oneGroupPerRow="oneGroupPerRow"
      @expansionModified="saveExpansion"
      @stateChanged="stateChanged"
    />
  </v-container>
</template>

<script>
import ToolView from "@/components/dataview/ToolView";
import ToolTable from "@/components/dataview/ToolTable";

export default {
  components: {
    ToolView,
    ToolTable,
  },

  data: () => ({
    //editable: true,
    injectedAllTools: {},
    savedExpansionData: {},
    overrideState: {},
    resetPageCtr: 0,
    currentLoadingState: false,
  }),
  props: {
    hierarchyStyle: { default: "full", type: String }, // full - all groups. none = zero groups, one = just the group containing the tools
    oneGroupPerRow: { default: false, type: Boolean }, // if set, then each top-level group will go into a new row  --->  BUG///  not working & outdated. use fullWidth flag instead
    overrideSublist: { default: "", type: String },
  },

  watch: {
    "$route.params.sublist": function () {
      console.log("View Page - set sublist");
      this.setViewSublist(this.sublistToUse);
    },
  },

  mounted() {
    console.log("~~~ View Page mounted");
    this.$store.state.menu.selecteditem = this.setViewSublist(this.sublistToUse);
  },

  computed: {
    MobileClass() {
      if (this.$vuetify.breakpoint.smAndDown) return "ma-0 pa-0";
      return "ml-0";
    },
    sublistToUse() {
      console.log("@@@ Get sublist");
      console.log(JSON.stringify(this.$route.params.sublist));
      if (this.overrideSublist !== "") return this.overrideSublist;
      return this.$route.params.sublist;
    },
    useHierarchyStyle() {
      console.log(">>> Change Hierarchy Style " + this.$route.params.option);
      var result = [];
      if (this.$route.params.option !== undefined) {
        result = this.$route.params.option.split("-");
      } else {
        result = this.hierarchyStyle.split("-");
      }
      // no sublist?  default to table view (e.g. for homepage)
      if (this.sublistToUse === undefined) result.push("table");
      return result;
    },
    activeSublist() {
      var showSublist = this.sublistToUse;
      if (showSublist === undefined) return "Undefined";
      return showSublist;
    },
    showAsTable() {
      //console.log("Show as table " + JSON.stringify(this.useHierarchyStyle));
      return this.useHierarchyStyle.includes("table");
    },
    overrideStateBuffered() {
      return JSON.parse(JSON.stringify(this.overrideState));
    },
    expansionList() {
      // get the expansion data if available
      var showSublist = this.sublistToUse;
      //console.log("VP - Get expansion list for " + showSublist);
      if (this.savedExpansionData[showSublist] !== undefined) {
        /*console.log(
          "ExpList " + JSON.stringify(this.savedExpansionData[showSublist])
        );*/
        return JSON.parse(JSON.stringify(this.savedExpansionData[showSublist]));
      }
      //console.log("ExpList undefined");
      return {};
    },

    authObject() {
      return {
        editEnable: this.$store.getters["menu/canEditTools"],
        permissions: this.$store.getters["user/permissions"],
      };
    },
    ToolViewList() {
      return JSON.parse(JSON.stringify(this.$store.getters["tools/getActiveViewList"]));
    },
    searchText() {
      // pick up the search text from the store (usually entered via title bar) and use it to narrow down cards
      return this.$store.getters.getSearchText;
    },

    hasFinishedLoading() {
      var isDoneLoading = !this.$store.getters["isLoading"];
      console.log("Query: Has finished loading: " + isDoneLoading);
      this.checkIfUpdateNeeded(isDoneLoading);
      return isDoneLoading;
    },
  },

  methods: {
    checkIfUpdateNeeded(newValue) {
      // this is not a good solution, should have something that watches dataload and then triggers... but without watching for change, we get an infinite loop at start
      if (newValue !== this.currentLoadingState) {
        console.log("trigger update !!!");
        this.currentLoadingState = newValue;
        var newTarget = this.setViewSublist(this.sublistToUse);
        this.$store.commit("menu/setMenuIndex", newTarget);
      }
    },
    setViewSublist(showSublist) {
      console.log("set view sublist " + JSON.stringify(showSublist));
      const showData = this.$store.getters["tools/getViewSublist"](showSublist);
      if (showData === undefined) {
        console.log("! showData undefined");
        return undefined;
      }
      console.log("show data " + JSON.stringify(showData));
      var depth = -1;
      if (showData.depth !== undefined) depth = showData.depth;
      console.log(depth);
      this.$store.dispatch("tools/setVisibleListDepth", {
        toolNameList: showData.tools,
        depth: depth,
      });
      this.$store.dispatch("menu/setQuickLink", showSublist);
      // return the index of the sublist. to be used when mounting, to highlight the correct menu item
      return this.$store.getters["menu/getIndexOfView"](showSublist);
    },
    saveExpansion(expansionList) {
      // if the expansion list changes, store that information by associating it with the page view parameter
      // to allow recoving it the next time we return to the same page
      //console.log("VP - Save expansion list " + JSON.stringify(expansionList));
      this.savedExpansionData[this.activeSublist] = JSON.parse(
        JSON.stringify(expansionList)
      );
    },
    stateChanged(state) {
      // possibly amend to ADD fields and not remove them (instead of replace)
      console.log("state changed on view page " + JSON.stringify(state));
      if (this.overrideState[state.Name] === undefined) {
        this.overrideState[state.Name] = state;
      } else {
        this.overrideState[state.Name]._state = {
          ...this.overrideState[state.Name]._state,
          ...state._state,
        };
      }
    },
  },
};
</script>
